import { Component } from "react";


const subtitle = "who we are";
const title = "We are a professional eSports Team";
const desc = "Tsinda Gamers is a new esports team dedicated to promoting awareness and interest in the competitive gaming community. We believe that teamwork and communication are key factors in success, and we strive to create an enjoyable experience for all our members.";


let AboutListContent = [
    {
        imgUrl: 'assets/images/about/icon-1.png',
        imgAlt: 'About Icon',
        title: '103k Community Earning',
        desc: 'Distinctively provide acces mutfuncto users whereas communicate leveraged services',
    },
    {
        imgUrl: 'assets/images/about/icon-2.png',
        imgAlt: 'About Icon',
        title: '34m+ Registered Players',
        desc: 'Distinctively provide acces mutfuncto users whereas communicate leveraged services',
    },
    {
        imgUrl: 'assets/images/about/icon-3.png',
        imgAlt: 'About Icon',
        title: '240k Streams Complete',
        desc: 'Distinctively provide acces mutfuncto users whereas communicate leveraged services',
    },
]



class AboutSection extends Component {
    render() { 
        const {imgUrl} = this.props;
        return (
            <section className="about-section">
                <div className="container">
                    <div className="section-wrapper padding-top">
                        <div className="row">
                            <div className="col-lg-6">
                                <div className="about-image">
                                    <img src={imgUrl} alt="about-image" />
                                </div>
                            </div>
                            <div className="col-lg-6 col-md-10">
                                <div className="about-wrapper flex items-center justify-center h-full">
                                    <div className="section-header">
                                        <p>{subtitle}</p>
                                        <h2>{title}</h2>
                                        <p className="about-description">{desc}</p>
                                    </div>
                                    {/* <div className="about-content">
                                        <p>{desc}</p>
                                        <ul className="about-list">
                                            {AboutListContent.map((val, i) => (
                                                <li className="about-item d-flex flex-wrap" key={i}>
                                                    <div className="about-item-thumb">
                                                        <img 
                                                            src={`${val.imgUrl}`} 
                                                            alt={`${val.imgAlt}`}
                                                        />
                                                    </div>
                                                    <div className="about-item-content">
                                                        <h5>{val.title}</h5>
                                                        <p>{val.desc}</p>
                                                    </div>
                                                </li>
                                            ))}
                                        </ul>
                                    </div> */}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        );
    }
}
 
export default AboutSection;