import { Fragment } from 'react';
import { Widget } from '@typeform/embed-react'
import Footer from '../component/layout/footer';
import Header from '../component/layout/header';
import PageHeader from '../component/layout/pageheader';

const title = 'Register Now';

const SignUp = () => {
    return (
        <Fragment>
            <Header />
            <PageHeader title={'Join Us'} curPage={'Sign Up'} />
            <div className="login-section padding-top padding-bottom">
                <div className=" container">
                    <div className="account-wrapper">
                        <h3 className="title">{title}</h3>
                        <Widget id="gBDS7d20" style={{ width: '100%', height: '450px' }} className="typeform-embed" />
                    </div>
                </div>
            </div>
            <Footer />
        </Fragment>
    )
}

export default SignUp;
