import { Component } from "react";
import { Link } from "react-router-dom";
import Rating from "../section/rating";
import SocialMedia from '../section/socialmedia';

const postTitle = "Top jackpot games";
const newsTitle = "Our Newsletter";
const desc = "Tsinda Gamers is a new esports team dedicated to promoting awareness and interest in the competitive gaming community. We believe that teamwork and communication are key factors in success, and we strive to create an enjoyable experience for all our members.";
const newsdesc = "Stay in the loop of all Tsinda Gamers ongoing and upcoming activities.";

let FooterItemList = [
    {
        imgUrl: 'assets/images/footer/01.jpg',
        imgAlt: 'Footer Blog Post',
        title: 'free Poker Game',
        proName: 'Poker',
        proPrice: '$230',
    },
    {
        imgUrl: 'assets/images/footer/02.jpg',
        imgAlt: 'Footer Blog Post',
        title: 'CLUB Poker Game',
        proName: 'Poker',
        proPrice: '$230',
    },
    {
        imgUrl: 'assets/images/footer/03.jpg',
        imgAlt: 'Footer Blog Post',
        title: 'ROYAL Poker Game',
        proName: 'Poker',
        proPrice: '$300',
    },
]

class Footer extends Component {
    constructor(props) {
        super(props);
        this.state = {
            newsName: '',
            newsEmail: '',
        };
    }
    render() {
        return (
            <footer className="footer-section">
                <div className="footer-top">
                    <div className="container">
                        <div className="row g-3 justify-content-center g-lg-0">
                            <div className="col-lg-4 col-sm-6 col-12">
                                <div className="footer-top-item lab-item">
                                    <div className="lab-inner">
                                        <div className="lab-thumb">
                                            <img src="assets/images/footer/icons/02.png" alt="email-icon" />
                                        </div>
                                        <div className="lab-content">
                                            <span>info@tsindagamers.com</span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-4 col-sm-6 col-12">
                                <div className="footer-top-item lab-item">
                                    <div className="lab-inner">
                                        <div className="lab-thumb">
                                            <img src="assets/images/footer/icons/03.png" alt="location-icon" />
                                        </div>
                                        <div className="lab-content">
                                            1.1.23 Norrsken house, Kigali KN 78 St
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="footer-middle padding-top padding-bottom" style={{ backgroundImage: "url(/assets/images/footer/bg.jpg)" }}>
                    <div className="container">
                        <div className="row padding-lg-top">
                            <div className="col-lg-4 col-md-6 col-12">
                                <div className="footer-middle-item-wrapper">
                                    <div className="footer-middle-item mb-lg-0">
                                        <div className="fm-item-title mb-4">
                                            <img src="logo192.png" alt="logo" />
                                        </div>
                                        <div className="fm-item-content">
                                            <p className="mb-4">{desc}</p>
                                            <div className="company-info pb-3 mb-3">
                                                <small>
                                                    General Payment Partner in the African region Tsinda Entertainment Limited
                                                    Powered by Steam (registered trademark of Valve Corporation)
                                                    Registration number PVT-BEUX5PZ5
                                                    Office: Starehe District, Kaunda Street, Queensway House, P.O. BOX 53854б Nairobi, Kenya.
                                                    Email: info@tsindagamers.com
                                                </small>
                                            </div>
                                            {/*<ul className="match-social-list d-flex flex-wrap align-items-center">*/}
                                            {/*    <SocialMedia />*/}
                                            {/*</ul>*/}
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-4 col-md-6 col-12">
                                <div className="footer-middle-item-wrapper">
                                    <div className="footer-middle-item-3 mb-lg-0">
                                        <div className="fm-item-title">
                                            <h4>{newsTitle}</h4>
                                        </div>
                                        <div className="fm-item-content">
                                            <p>{newsdesc}</p>
                                            <form>
                                                <div className="form-group mb-4">
                                                    <input
                                                        type="text"
                                                        name="name"
                                                        id="item01"
                                                        className="form-control"
                                                        value={this.state.newsName}
                                                        onChange={(e) => { this.setState({ newsName: e.target.value }); }}
                                                        placeholder="Your Name *"
                                                    />
                                                </div>
                                                <div className="form-group mb-2">
                                                    <input
                                                        type="email"
                                                        name="name"
                                                        id="item02"
                                                        className="form-control"
                                                        value={this.state.newsEmail}
                                                        onChange={(e) => { this.setState({ newsEmail: e.target.value }); }}
                                                        placeholder="Your Email *"
                                                    />
                                                </div>
                                                <button type="submit" className="default-button"><span>Send Message <i className="icofont-circled-right"></i></span></button>
                                            </form>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="footer-bottom">
                    <div className="container">
                        <div className="row">
                            <div className="col-12">
                                <div className="footer-bottom-content text-center">
                                    <p><Link to="/">Tsinda Gamers. &copy; {new Date().getFullYear()} All Rights Reserved.</Link></p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </footer>
        );
    }
}

export default Footer;